/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Mobile menu
        if (viewportWidth < 1024) {
          // Nav toggle
          $('.c-nav__toggle').on('click', function(e){
            e.preventDefault();

            $(this).toggleClass('is-active');

            $('.c-nav__list').slideToggle();
          });

          // Dropdown toggle
          $('.dropdown-toggle').click(function() {
            $(this).parent('.has-dropdown').toggleClass('is-active').siblings('li').removeClass('is-active');
          });
        }

        //*Mobile Menu*//
        $( ".c-nav__toggle" ).click(function(e) {
          e.preventDefault();

          $('.menu-primary-navigation').animate({height: 'toggle'});
        });

        // Buttons w/ scroll
        $('.c-btn--scroll').click(function(e) {
          e.preventDefault();

          var section = $(this).attr('href');

          $('html, body').stop().animate({
            scrollTop: $(''+section+'').offset().top
          }, 1000);
        });

        // Carousel
        if (viewportWidth < 1024) {
          $('.c-home--featured-services .owl-carousel').owlCarousel({
            loop: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            autoHeight: false,
            responsive: {
              0 : {
                items: 1,
              },
              667 : {
                items: 2
              }
            }
          });
        }

        $('.c-home--printing .owl-carousel').owlCarousel({
          loop: true,
          nav: true,
          navText: ['', ''],
          dots: false,
          autoplay: true,
          autoplayTimeout: 7000,
          autoplayHoverPause: true,
          autoplaySpeed: 1000,
          autoHeight: false,
          responsive: {
            0 : {
              items: 1,
            },
            667 : {
              items: 2
            },
            768 : {
              items: 4
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
